<template>
  <div class="be-row justify-content-center h-100">
    <div class="col col-xl-12 px-0">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WithdrawPage",

  computed: {
    ...mapGetters("auth", ["userConfig"]),
  },
};
</script>

<style scoped></style>
